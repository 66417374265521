import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ResponseDeviceData, DeviceInfo, Response, ResponseData, Point, DataPoints, Selectivity } from 'src/app/common/models/antar-api-response.model';
import { AntarConstants } from 'src/app/common/antar.constants';
import { RequestData } from 'src/app/common/models/antart-api-request.model';
@Injectable( {
	providedIn: 'root'
} )

export class LoadDataService {
	apiUrl: string = '';
	antarCurveGenerationApiUrl = environment.apiUrl + 'GenerateTCCData';
	selectivityResultApiUrl = environment.apiUrl + 'ProtectionSelectivity/GetCurveSelectivityResult';
	multivendorApiUrl = environment.multivendorApiUrl;
	launchSystem = environment.launchSystem;
	commonResponse!:Response;
	selectivityResultByTableApiUrl = environment.apiUrl + 'ProtectionSelectivity/GetSelectivityByTable';
	point: number[][] = [];
	constructor ( private http: HttpClient ) {
		if ( this.launchSystem !== undefined )
		{
			console.log( 'environment.lunchsystem ', this.launchSystem );
			this.apiUrl = this.launchSystem == AntarConstants.LAUNCHSYSTEM.ANTARES ? this.antarCurveGenerationApiUrl : this.multivendorApiUrl;
		}
	}
	fetchGraphPoints ( curveData: RequestData ): Observable<ResponseData> {
		if ( this.launchSystem === AntarConstants.LAUNCHSYSTEM.CEL ) {
			return this.http.post<ResponseData>( this.multivendorApiUrl, curveData ).pipe(
				map( response => {
					response.tccDataResponse.curveDataPoints.forEach( curvedata => {
						if ( curvedata['settingsData'] === undefined ) {
							curvedata.settingsData = {
								deviceId: curvedata.deviceId,
								deviceType: 'Circuit Breaker',
								deviceLegendName: curvedata.deviceLegendName,
								legendDataList: curvedata.legendDataList !== null ? curvedata.legendDataList : null
							};
						}
					} );
					return response;
				} )
			);
		} else {
			const requestDataForApi:RequestData = {
				applicationName: curveData.applicationName,
				data: curveData.data.filter( item => item.protectionDeviceType != AntarConstants.LABEL_TYPE['TRANSFORMER'] ),
				networkParameters: curveData.networkParameters
			};
			return this.http.post<Response>( this.apiUrl, requestDataForApi ).pipe(
				map( response => response.data )
			);
		}
	}
	getSelectivityResult ( curvePointsData: ResponseDeviceData[] ): Observable<Selectivity> {
		const deviceData: DeviceInfo[] = curvePointsData.map( ( data: ResponseDeviceData ) => {
			const points = this.launchSystem === AntarConstants.LAUNCHSYSTEM.ANTARES
				? data.points
				: this.generateDataPoints( data.dataPoints as DataPoints[] );

			return {
				deviceId: data.deviceId,
				breakerPositioning: data.breakerPositioning,
				points
			};
		} );
		return this.http.post<Response>( this.selectivityResultApiUrl, { 'curveDataContent': deviceData } )
				.pipe( map( response => response.data ) );
	}
	getSelectivityResultByTable ( cbData: RequestData ): Observable<Response> {
		const requestDataForApi:RequestData = {
			applicationName: cbData.applicationName,
			data: cbData.data.filter( item => item.protectionDeviceType != AntarConstants.LABEL_TYPE['TRANSFORMER'] ),
			networkParameters: cbData.networkParameters
		};
		return this.http.post<Response>( this.selectivityResultByTableApiUrl, requestDataForApi ).pipe(
			map( response => response )
		);
	}
	private generateDataPoints ( datapoints: DataPoints[] ):Point[] {
		let minpoints:number[][] = [];
		let maxpoints: number[][] = [];
		let point:Point[] = [];

		const processPoints = ( points:number[][] ) => {
			return points
				.slice()
				.sort( ( a, b ) => a[0] - b[0] )
				.map( a => ( { 'x': a[0], 'y': a[1] } ) );
		};

		datapoints.forEach( eachset => {
			if ( eachset.points ) {
			if ( eachset.curveName === AntarConstants.CEL_CURVE_NAME['Min'] ) {
				minpoints = this.optimizepoint( eachset.points, 0.000001 );
			}
			if ( eachset.curveName === AntarConstants.CEL_CURVE_NAME['Max'] ) {
				maxpoints = this.optimizepoint( eachset.points, 0.000001 );
			}}
		} );
		if ( minpoints && maxpoints ) {
			this.point = [] ;
			const combinedPoints: Point[] = [
				...processPoints( minpoints ),
				...processPoints( maxpoints )
			];
			point.push( ...combinedPoints );
		}
		else if ( minpoints.length > 0 && maxpoints.length == 0 )
			point = minpoints.map( a => ( { 'x': a[0], 'y': a[1] } ) );
		else if ( maxpoints.length > 0 && minpoints.length == 0 )
			point = minpoints.map( a => ( { 'x': a[0], 'y': a[1] } ) );
		return point;
	}
	private optimizepoint ( point:Point[], precision: number ):number[][]
    {
        if ( point )
        {
            this.point.push( [ point[0].x, point[0].y ] );
            for ( let i = 0;i < point.length - 2;i++ )
            {
                const check1 = point[i];
                const point_id = point[i + 1];
                const check2 = point[i + 2];

                if ( precision == null || !this.IsOnLine( check1, check2, point_id, precision ) )
                {
                    this.point.push( [ point_id.x, point_id.y ] );
                }
            }
            if ( point.length > 1 )
            {
                this.point.push( [ point[point.length - 1].x, point[point.length - 1].y ] );
            }
        }
        return this.point;
    }
    private IsOnLine ( endPoint1:Point, endPoint2:Point, checkPoint:Point, precision:number ):boolean
    {
        return Math.abs( ( ( checkPoint.y - endPoint1.y ) / ( checkPoint.x - endPoint1.x ) ) -
                   ( ( endPoint2.y - endPoint1.y ) / ( endPoint2.x - endPoint1.x ) ) ) < precision;
    }
}

